<template>
  <div class="wrapper">
    <vxe-toolbar>
      <template v-slot:buttons>
        <vxe-button
          v-if="seachStatus"
          status="my-orange"
          @click="showSearch(false)"
        >隐藏查询</vxe-button>
        <vxe-button
          v-if="!seachStatus"
          status="my-orange"
          icon="fa fa-search"
          @click="showSearch(true)"
        >查询</vxe-button>
        <vxe-button
          v-permission="'AddAgedEatMechaismManage'"
          status="my-orange"
          icon="fa fa-plus"
          @click="goPage('add')"
        >添加</vxe-button>
      </template>
    </vxe-toolbar>
    <div v-if="seachStatus" class="search">
      <vxe-form
        title-align="right"
        title-width="120"
        title-colon
        :data="searchData"
        @submit="searchEvent"
        @reset="searchReset"
      >
        <vxe-form-item title="机构名称" span="8">
          <vxe-input
            v-model="searchData.orgName"
            placeholder="请输入机构名称"
            clearable
          />
        </vxe-form-item>
        <vxe-form-item title="所属区域">
          <vxe-select
            v-model="searchData.area"
            placeholder="请选择区县"
            style="width: 100%"
            @change="areaChange"
          >
            <vxe-option
              v-for="item in areaList"
              :key="item"
              :label="item"
              :value="item"
            />
          </vxe-select>
        </vxe-form-item>
        <vxe-form-item label-width="0">
          <vxe-select
            v-model="searchData.street"
            placeholder="请选择街道"
            style="width: 100%"
          >
            <vxe-option
              v-for="item in streetList"
              :key="item"
              :label="item"
              :value="item"
            />
          </vxe-select>
        </vxe-form-item>
        <vxe-form-item label-width="0">
          <vxe-input
            v-model="searchData.address"
            placeholder="请输入详细地址"
            auto-complete="off"
          />
        </vxe-form-item>
        <vxe-form-item title="状态" span="8">
          <vxe-select v-model="searchData.status" placeholder="请选择状态" clearable>
            <vxe-option
              v-for="item of stateList"
              :key="item.value"
              :value="item.value"
              :label="item.label"
            />
          </vxe-select>
        </vxe-form-item>
        <vxe-form-item title="审核状态" span="8">
          <vxe-select v-model="searchData.auditStatus" placeholder="请选择审核状态" clearable>
            <vxe-option
                v-for="(item, index) in auditStatusArray"
                :key="index"
                :label="item"
                :value="index"
            />
          </vxe-select>
        </vxe-form-item>
        <vxe-form-item align="center" span="24">
          <vxe-button
            type="submit"
            status="my-orange"
            icon="fa fa-check"
          >应用</vxe-button>
          <vxe-button
            type="reset"
            status="my-orange"
            icon="fa fa-undo"
          >重置</vxe-button>
        </vxe-form-item>
      </vxe-form>
    </div>
    <div class="table">
      <vxe-grid
        border
        stripe
        resizable
        auto-resize
        :columns="tableColumn"
        :data="tableData"
        :loading="loading"
      >
        <template v-slot:orgName="{ row }">
          <p class="link-a" @click="goPage('detail', row)">
            {{ row.orgName }}
          </p>
        </template>
        <template v-slot:leaveTime="{ row }">
          <span>{{ $tools.reversalTime(row.leaveTime) }}</span>
        </template>
        <template v-slot:factTime="{ row }">
          <span>{{ $tools.reversalTime(row.factTime) }}</span>
        </template>
        <template v-slot:operation="{ row }">
          <vxe-button
            v-permission="'AddAgedEatMechaismManage'"
            type="text"
            status="primary"
            @click="goPage('edit', row)"
          >修改</vxe-button>
          <vxe-button
            v-permission="'AgedEatMechaismManageDeleteButton'"
            type="text"
            status="primary"
            @click="remove(row.id)"
          >删除</vxe-button>
        </template>
      </vxe-grid>
    </div>
    <!-- 分页 -->
    <div class="page">
      <vxe-pager
        :current-page.sync="tablePage.currentPage"
        :page-size.sync="tablePage.pageSize"
        :page-sizes="tablePage.pageSizes"
        :total="tablePage.totalResult"
        :layouts="layouts"
        @page-change="pageChange"
      />
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex'
export default {
  data() {
    return {
      loading: false,
      seachStatus: false,
      searchData: {},
      auditStatusArray: ['待审核', '正常', '拒绝'],
      areaList: [],
      streetList: [],
      stateList: [
        {
          label: '建设中',
          value: 0
        },
        {
          label: '已建成',
          value: 1
        },
        {
          label: '运营中',
          value: 2
        },
        {
          label: '停业中',
          value: 3
        }
      ], // 建设中、已建成、运营中、停业中
      // 分页的参数配置
      tablePage: {
        totalResult: 0,
        currentPage: 1,
        pageSize: 10,
        align: 'left',
        pageSizes: [10, 20, 50, 100, 500, 1000],
        perfect: true
      },
      tableColumn: [
        {
          type: 'seq',
          title: '序号',
          width: 50,
          align: 'center'
        },
        {
          field: 'orgName',
          title: '机构名称',
          showOverflow: true,
          align: 'center',
          minWidth: '120',
          slots: {
            default: 'orgName'
          }
        },
        {
          field: 'standard',
          title: '助餐标准',
          showOverflow: true,
          align: 'center',
          minWidth: '120'
        },
        {
          field: 'deliverys',
          title: '是否配送',
          showOverflow: true,
          align: 'center',
          minWidth: '90'
        },
        {
          field: 'address',
          title: '机构地址',
          showOverflow: true,
          align: 'center',
          minWidth: '200'
        },
        {
          field: 'mainPerson',
          title: '负责人',
          showOverflow: true,
          align: 'center',
          minWidth: '120'
        },
        {
          field: 'mainPersonTel',
          title: '联系方式',
          showOverflow: true,
          align: 'center',
          minWidth: '120'
        },
        {
          field: 'statuss',
          title: '状态',
          showOverflow: true,
          align: 'center',
          minWidth: '80'
        },
        {
          field: 'auditStatus',
          title: '审核状态',
          showOverflow: true,
          align: 'center',
          minWidth: '140',
          formatter: data => {
            if (data.row.auditStatus in this.auditStatusArray) {
              let text = this.auditStatusArray[data.row.auditStatus]
              if (data.row.auditStatus === 2 && data.row.reason) {
                text += `：${data.row.reason}`
              }
              return text
            } else {
              return ''
            }
          }
        },
        {
          field: 'account',
          title: '登录账号',
          showOverflow: true,
          align: 'center',
          minWidth: '120'
        },
        {
          title: '操作',
          width: 150,
          showOverflow: true,
          slots: {
            default: 'operation'
          }
        }
      ],
      tableData: []
    }
  },
  computed: {
    ...mapState(['layouts']),
    ...mapGetters(['seqId'])
  },
  watch: {
    seqId() {
      // this.getLists()
    }
  },
  created() {
    this.initView()
  },
  methods: {
    ...mapActions([
      'getmechanismList',
      'delmealOrg',
      'getSysDictServe',
      'getArealistServe',
      'getstrealistServe',
      'getHomePersonListServe'
    ]),
    initView() {
      // this.getTeamList();
      // this.getCategoryList();
      this.getLists()
      this.getAreaList()
    },

    // 获取区县list
    getAreaList() {
      this.getArealistServe().then((res) => {
        if (res.code == 200) {
          this.areaList = res.data
        }
      })
    },
    areaChange(val) {
      if (val) {
        this.getStreetList(this.searchData.area)
      }
    },
    // 获取街道list
    getStreetList(val) {
      const params = {
        areaName: val
      }
      this.getstrealistServe(params).then((res) => {
        if (res.code == 200) {
          this.streetList = res.data
        }
      })
    },
    // 是否显示查询功能
    showSearch(bool) {
      this.seachStatus = bool
    },
    goStaff(type, itemData) {
      this.$router.push({
        name: 'AddStaff',
        params: {
          type: type,
          id: type == 'add' ? 'add' : itemData.id
        },
        query: {
          item: type == 'add' ? 'add' : JSON.stringify(itemData)
        }
      })
    },
    goAged(type, itemData) {
      this.$router.push({
        name: 'addAged',
        params: {
          type: type,
          id: type == 'add' ? 'add' : itemData.id
        },
        query: {
          item: type == 'add' ? 'add' : JSON.stringify(itemData)
        }
      })
    },
    goPage(type, itemData) {
      if (type == 'detail') {
        this.$router.push({
          name: 'MechaismDetail',
          params: {
            type: type,
            id: itemData.id
          },
          query: {
            item: type == 'add' ? 'add' : JSON.stringify(itemData)
          }
        })
      } else {
        this.$router.push({
          name: 'AddAgedEatMechaismManage',
          params: {
            type: type,
            id: type == 'add' ? 'add' : itemData.id
          },
          query: {
            item: type == 'add' ? 'add' : JSON.stringify(itemData)
          }
        })
      }
    },
    getLists() {
      this.loading = true
      this.getmechanismList({
        current: this.tablePage.currentPage,
        size: this.tablePage.pageSize,
        param: {
          ...this.searchData
        }
      }).then((res) => {
        if (res.code == 200) {
          this.tableData = res.data.records.map((item) => {
            return {
              ...item,
              deliverys: item.delivery == 0 ? '是' : '否',
              statuss: this.stateList[Number(item.status)].label
            }
          })
          this.tablePage.totalResult = +res.data.total
        }
        this.loading = false
      })
    },
    // 分页功能
    pageChange(item) {
      if (item.type == 'size') {
        this.tablePage.currentPage = 1
      } else {
        this.tablePage.currentPage = item.currentPage
      }
      this.getLists()
    },
    searchEvent() {
      console.log(this.searchData)
      this.seachStatus = false
      this.getLists()
    },
    searchReset() {
      this.searchData = {}
      this.getLists()
    },
    remove(id) {
      this.$XModal.confirm('您确定要删除该数据?').then((type) => {
        if (type === 'confirm') {
          console.log('执行删除操作')
          this.delete(id)
        }
      })
    },
    delete(id) {
      this.delmealOrg({
        id
      }).then((res) => {
        if (res.code == 200) {
          this.$XModal.message({
            message: '删除成功',
            status: 'success'
          })
          this.getLists()
        }
        this.loading = false
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper {
  .datetime {
    display: flex;
    align-items: center;
    span {
      margin: 0 3px;
    }
  }
}
.sign i {
  margin-right: 3px;
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
}
</style>
